.ssr-config {
    .ant-card-cover {
        background: linear-gradient(60deg, #26c6da, #00acc1);
        border-radius: 3px;
        box-sizing: border-box;
        padding: 20px;
    }
    .address {
        color: #4caf50;
        margin-right: 1rem;
    }
    .update-port {
        width: 100%;
        height: 40px;
        font-weight: bold;
        margin-top: 2rem;
    }
    &.mobile {
        .config-card {
            max-width: 350px;
        }
        .update-port {
            max-width: 350px;
        }
    }
}