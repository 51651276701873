.app-header {
    display: flex;
    margin-bottom: 7.5px;
    box-shadow: 0 0px 3px 0 rgba(0,0,0,.2);

    .ant-card-body {
        padding: 18px;
    }
    .icons {
        cursor: pointer;
        font-size: 18px;
        &:hover {
            color: #1890ff;
        }

        &.__back {
            display: inline-block;
            margin-left: 18px;
        }
    }
    .title {
        font-size: 18px;
        display: inline-block;
        margin-left: 18px;
    }
}